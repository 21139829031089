import React from 'react'
import { CleanDelivery, CleanInstagram, CleanWhatsapp } from '../../components/AppIcon'

export const HubungiKamiComponent = () => {
  return (
    <>
        <div className='row'>
          <div className='col text-center'>
            <h2 className='fw-bold'>Hubungi Kami</h2>
            <p>Via <b>Whatsapp : 0857 1566 5516  </b> atau klik <a href="https://www.google.com/url?q=https%3A%2F%2Fpesan.link%2Fadminzerpolaundry2&amp;sa=D&amp;sntz=1&amp;usg=AOvVaw3Sl7R0wyNQHTeqGzzDTu6E" target="_blank"> <CleanWhatsapp /> </a></p>
            <p>Via <b>instagram : zerpolaundry  </b> atau klik <a href="https://www.instagram.com/zerpolaundry" target="_blank"> <CleanInstagram /> </a></p>
          </div>
        </div>

        <div className='row justify-content-center'>
          <div className='col-md-6 text-center'>
            <h2>Lokasi Kami</h2>
            <p>Jl Mapalus No 10, Pegangsaan Dua, Kelapa Gading, Jakarta Utara</p>

            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3966.674032564919!2d106.908422!3d-6.174377!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f4d5c6db773d%3A0xc6423269e47c05cb!2sJl.%20Mapalus%20No.10%2C%20RT.4%2FRW.6%2C%20Pegangsaan%20Dua%2C%20Kec.%20Klp.%20Gading%2C%20Kota%20Jkt%20Utara%2C%20Daerah%20Khusus%20Ibukota%20Jakarta%2014240%2C%20Indonesia!5e0!3m2!1sid!2sus!4v1671254073512!5m2!1sid!2sus" width={'96%'} height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />

          </div>
        </div>
    </>
  )
}
