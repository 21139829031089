import React from 'react'
import { CleanClothes, CleanSofa, LaundryBasket } from '../../components/AppIcon'

import imgKarpet from '../../assets/images/karpet.jpg'
import imgBarangbBayi from '../../assets/images/barang-bayi.jpg'
import imgKoper from '../../assets/images/koper.jpg'
import imgSepatu from '../../assets/images/sepatu.jpg'
import imgBoneka from '../../assets/images/boneka.jpg'
import imgSofa from '../../assets/images/sofa.jpg'

export const LayananCompnent = () => {
  return (
    <>
        <div className='row mb-5'>
            <h2 className='fw-bold text-center'>Layanan Kami</h2>
        </div>
        <div className='row mb-5'>
            <div className='shadow card-body card-layanan rounded p-3 m-3 col-md-3'>
                <div className='text-center'>
                        <LaundryBasket />
                    <h3 className='mt-3  fw-bold'>Laundry Kiloan</h3>
                    <p>
                        Jasa laundry baju kiloan  cocok untuk pakaian sehari-hari. Sudah termasuk cuci, gosok dan lipat. Bisa antar-jemput juga.
                    </p>
                </div>
            </div>

            <div className='shadow card-body card-layanan rounded p-3 m-3 col-md-3'>
                <div className='text-center'>
                        <CleanClothes />
                    <h3 className='mt-3  fw-bold'>Laundry Satuan</h3>
                    <p>
                        Jasa laundry bedcover, Sepatu, Boneka, Koper, dan lainnya. Bisa antar-jemput juga.
                    </p>
                </div>
            </div>

            <div className='shadow card-body card-layanan rounded p-3 m-3 col-md-3'>
                <div className='text-center'>
                        <CleanSofa />
                    <h3 className='mt-3  fw-bold'>Laundry Spesialis</h3>
                    <p>
                        Jasa laundry Karpet, Baby Gears, Sofa, Springbed, dan lainnya. Bisa antar-jemput juga.
                    </p>
                </div>
            </div>
        </div>

        <div className='row justify-content-center'>
            <div className='col-md-6'>

                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="false">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={1} aria-label="Slide 2" />
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={2} aria-label="Slide 3" />
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={3} aria-label="Slide 4" />
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={4} aria-label="Slide 5" />
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={5} aria-label="Slide 6" />
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                        <img src={ imgKarpet }  className="rounded d-block w-100" alt={ process.env.REACT_APP_NAME +" foto Laundry Karpet" } />
                        <div className="carousel-caption d-none d-md-block">
                            <h5>Laundry Karpet</h5>
                        </div>
                        </div>
                        <div className="carousel-item">
                            <img src={ imgBarangbBayi }  className="rounded d-block w-100" alt={ process.env.REACT_APP_NAME +" foto Laundry Karpet" } />
                            <div className="carousel-caption d-none d-md-block">
                                <h5>Laundry Baby Gears</h5>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src={imgKoper} className="rounded d-block w-100" alt={ process.env.REACT_APP_NAME +" foto Laundry Karpet" } />
                            <div className="carousel-caption d-none d-md-block">
                                <h5>Laundry Koper</h5>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src={imgSepatu} className="rounded d-block w-100" alt={ process.env.REACT_APP_NAME +" foto Laundry Sepatu" } />
                            <div className="carousel-caption d-none d-md-block">
                                <h5>Laundry Sepatu</h5>
                            </div>
                        </div>
                        
                        <div className="carousel-item">
                            <img src={imgBoneka} className="rounded d-block w-100" alt={ process.env.REACT_APP_NAME +" foto Laundry Boneka" } />
                            <div className="carousel-caption d-none d-md-block">
                                <h5>Laundry Boneka</h5>
                            </div>
                        </div>

                        <div className="carousel-item">
                            <img src={imgSofa}  className="rounded d-block w-100" alt={ process.env.REACT_APP_NAME +" foto Laundry Sofa" } />
                            <div className="carousel-caption d-none d-md-block">
                                <h5>Laundry Sofa</h5>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true" />
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div> 
        </div>
    </>
  )
}
