import React from 'react'
import { CleanDelivery, CleanInstagram, CleanWhatsapp } from '../../components/AppIcon'
import bgImage from '../../assets/images/jumbotron-home.jpg'
import { LayananCompnent } from './LayananCompnent'
import { HubungiKamiComponent } from './HubungiKamiComponent'

import '../../index.css';



import Slider from "react-slick";
import { SlickNextArrow } from '../../components/SlickNextArrow'
import { SlickPrevArrow } from '../../components/SlickPrevArrow'

export const HomeMain = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPrevArrow />
  };

  return (
    <>
      <div className='container-fluid mb-5' id="Home">
        <div className='row justify-content-center'>
          <div className='col-md-12 home_bg' style={
              {
                backgroundSize: 'cover', backgroundPosition: 'center center',
                backgroundImage: `url(${bgImage})`
              }
            }>
          </div>
          <div className='col-md-6'>
            <h1 className='fw-bold text-primary-app' style={{ marginTop: '-25vh'}}>Zerpo Laundry</h1>
            <h2> Laundry Antar Jemput  ~~<CleanDelivery height={ 100 }/> </h2>
          </div>
        </div>
      </div>
      <div className='container mt-5 mb-5 pb-3' id="TentangKami">
        <div className='row mb-5'>
            <h2 className='fw-bold text-center'>Tentang Kami</h2>
        </div>
        <div className='row mb-5'>
            <div className='col-md-12'>
              <p className='fs-4'>
                <b>Zerpo Laundry</b> telah berdiri dan melayani pelanggan sejak <b>2015</b>. 
              </p>
              <p className='fs-4'>
                Zerpo Laundry menyediakan layanan Laundry 
                Kiloan, Satuan Dan Spesialis.
              </p>
              <p className='fs-4'>Kami siap melayani anda untuk melakukan proses laundry kiloan, satuan, spesialis.
                Kami menggunakan chemical ternama di indonesia  yang ramah lingkungan 
                Selain costumer reguler, Kami juga melayani layanan jasa laundry kepada perusahaan.
              </p>
              <div className='row justify-content-center'>
                <div className='col-md-10'>
                  <div className='text-center'>
                    <h2 className='fw-bold text-center mb-5'> Our Clients </h2>
                    <Slider {...settings}>
                      <div>
                        <h3>TV ONE</h3>
                      </div>
                      <div>
                        <h3>TIKI</h3>
                      </div>
                      <div>
                        <h3>SUZUKI</h3>
                      </div>
                      <div>
                        <h3>ASURANSI BUMIPUTRA</h3>
                      </div>
                      <div>
                        <h3>JUBILE SCHOOL</h3>
                      </div>
                      <div>
                        <h3>POLDA METRO JAYA</h3>
                      </div>
                      <div>
                        <h3>KAI</h3>
                      </div>
                      <div>
                        <h3>ASTRA OTO PARTS</h3>
                      </div>
                      <div>
                        <h3>DIRECT ENGLISH</h3>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div className='container mb-5' id="Layanan">
          <LayananCompnent />
      </div>
      <div className='container' id="HubungiKami">
            <HubungiKamiComponent />
      </div>
    </>
  )
}
