import React from 'react'
import laundry_basket from '../assets/images/laundry-basket.png'
import clean_clothes from '../assets/images/clean-clothes.png'
import clean_sofa from '../assets/images/clean-sofa.png'
import clean_delivery from '../assets/images/clean-delivery.png'
import clean_whatsapp from '../assets/images/whatsapp.png'
import clean_instagram from '../assets/images/instagram.png'


export const LaundryBasket = ( props ) => {
    const sizeDefault = (props.height? props.height: 50);

    return <img src={laundry_basket} alt="Logo" style={{ height: sizeDefault}}/>;
}

export const CleanClothes = (props) => {
    const sizeDefault = (props.height? props.height: 50);

    return <img src={clean_clothes} alt="Logo" style={{ height: sizeDefault}}/>;
}

export const CleanSofa = (props) => {
    const sizeDefault = (props.height? props.height: 50);

    return <img src={clean_sofa} alt="Logo" style={{ height: sizeDefault}}/>;
}


export const CleanDelivery = ( props ) => {
    const sizeDefault = (props.height? props.height: 50);

    return <img src={clean_delivery} alt="Logo" style={{ height: sizeDefault }}/>;
}

export const CleanWhatsapp = ( props ) => {
    const sizeDefault = (props.height? props.height: 50);

    return <img src={clean_whatsapp} alt="Logo" style={{ height: sizeDefault }}/>;
}

export const CleanInstagram = ( props ) => {
    const sizeDefault = (props.height? props.height: 50);

    return <img src={clean_instagram} alt="Logo" style={{ height: sizeDefault }}/>;
}
