import React from 'react'

export const NavOne = () => {
  return (
    <>
    <nav className="navbar navbar-expand-lg navbar-dark bg-app bg-gradient sticky-top">
        <div className="container">
            <a className="navbar-brand text-white" href="#">{ process.env.REACT_APP_NAME}</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#Home">Home</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#TentangKami">Tentang Kami</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#Layanan">Layanan</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#HubungiKami">Hubungi Kami</a>
                </li>
            </ul>
            {/* <form className="d-flex" role="search">
                <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                <button className="btn btn-outline-success" type="submit">Search</button>
            </form> */}
            </div>
        </div>
    </nav>

    </>
  )
}
