import React from 'react'
import { CleanInstagram, CleanWhatsapp } from './AppIcon'

export const AppFooter = () => {
  return (
    <>
      <div className='container-fluid'>
        <div className='row text-center my-5'>
          
          <div className='col-md-6'>
            <p>
               <a href="https://www.instagram.com/zerpolaundry/" target="_blank"> @zerpolaundry <CleanInstagram height={30} /> </a> 
            </p>
          </div>

          <div className='col-md-6'>
            <p>
              0857 - 1566 - 5516 <a href="https://www.google.com/url?q=https%3A%2F%2Fpesan.link%2Fadminzerpolaundry2&amp;sa=D&amp;sntz=1&amp;usg=AOvVaw3Sl7R0wyNQHTeqGzzDTu6E" target="_blank"> <CleanWhatsapp height={30} /> </a>
            </p>
          </div>
          <div className='col-md-12'>
              <p>
                { process.env.REACT_APP_NAME } &copy; { new Date().getFullYear() } 
              </p>
          </div>
        </div>
      </div>
    </>
  )
}
