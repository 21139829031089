import React from 'react'
import { primaryColor } from './Constant';

export const SlickPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: primaryColor, borderRadius: 50 }}
        onClick={onClick}
      />
    );
}
